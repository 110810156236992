import React from 'react'
import headerImg from "../Img/title_logo.png"
import "./Header.css"
function Header() {
    return (
        <div className='header'>

            <a href='/'>
                <img src={headerImg} className="logo"></img>
            </a>

        </div>
    )
}

export default Header