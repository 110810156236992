import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { db } from '../firebase';
import firebase from 'firebase/compat/app';
import "./form_result.css"

function Form_result() {
    const location = useLocation();

    const { name, email, message, reply } = location.state;
    const navigate = useNavigate();
    function back() {
        navigate("/Form", { state: { name: name, email: email, message: message, reply: reply } })
    }
    function sendForm(e) {
        e.preventDefault();

        db.collection("form").add({
            message: message,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            name: name,
            email: email,
            reply: reply
        });
        alert("送信しました")
        navigate("../")
    }
    return (
        <form className="contact-form" >
               {document.title = "問合せ確認"}
            <label>お名前</label>
            <p>{name}</p>
            <label>メールアドレス</label>
            <p>{email}</p>
            <label>お問い合わせ内容</label>
            <p>{message}</p>
            <label>返信を希望しますか？</label>
            {reply ? <p>はい</p> : <p>いいえ</p>}

            <button className="back_button" onClick={back}>内容入力へ戻る</button>
            <button className="submit_button" onClick={sendForm}>--送信--</button>
        </form >
    )
}

export default Form_result