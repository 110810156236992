import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/storage";
import { getAuth } from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth";

//valoteitenのほう
const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBNIBhNC7kHFJVZ9eKaaVbo8M9_Xg94C1A",
    authDomain: "valoteiten-e3409.firebaseapp.com",
    projectId: "valoteiten-e3409",
    storageBucket: "valoteiten-e3409.appspot.com",
    messagingSenderId: "509739760456",
    appId: "1:509739760456:web:e5d91fbd3d60d7af95517d",
    measurementId: "G-D6EFPS795V"
});

const db = firebaseApp.firestore();
const storage = firebase.storage();
const auth = getAuth(firebaseApp)
const provider = new GoogleAuthProvider();
export { db, storage, auth, provider }