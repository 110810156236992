import React from 'react'
import { useLocation } from 'react-router-dom';
import "./news_result.css"
function News_page() {
    const location = useLocation();
    const result = location.state.arrayData
    return (
        <div className='news_area'>
            <div className='news_title_area'>
                <h2 className='news_title'>{result.newsTitle}</h2>
                <p className='news_date'>作成日 {result.date}</p>
            </div>

            <p className='news_message'>{result.newsText}</p>
        </div>
    )
}

export default News_page