import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import firebase from 'firebase/compat/app';

function Test(props) {
    const [sendmessage, setSendmessage] = useState("");
    function sendMessage(e) {
        if (sendmessage) {


            e.preventDefault();

            db.collection("comments").add({
                text: sendmessage,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                contentId: props.id
            });
            setSendmessage("")
        }
    }

    //コメント取得
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        db
            .collection("comments")
            .where("contentId", "==", props.id)
            .orderBy("createdAt", "desc")
            .onSnapshot((snapshot) => {
                setMessages(snapshot.docs.map((doc) => doc.data()));
            })

    }, [])

    return (
        <div>
            <div className='comment_submit_area' >
                <textarea className='comment_input' type="text" placeholder='コメント' onChange={(e) => setSendmessage(e.target.value)}
                    value={sendmessage}
                ></textarea>
                <input className='comment_submit' type="button" onClick={sendMessage} value="送信"></input>
            </div>
            <div className='comments'>
                {messages.map((result) => (
                    <div key={result} className="comment">
                        <p className='value'>{result.text}</p>
                        <p className='createdAt'>{result.createdAt ? result.createdAt.toDate().toLocaleDateString() : <>今</>}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Test