import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import Content_mini from './Content_mini';
import "./TopPage.css"
import Search from './Search';
import News from './News';
import TopComment from './TopComment';

function TopPage() {

    const [contents, setContents] = useState([]);
    useEffect(() => {
        db
            .collection("contents")
            .orderBy("createdAt", "desc")
            // .limit(20)
            .onSnapshot((snapshot) => {
                setContents(snapshot.docs.map((doc) => doc.data()));
            })

    }, [])

    return (
        <div className="content_list" style={{
            backgroundColor: "black",
            padding: "6px"
        }}>


            <Link to="/Search_result?orderBy=createdAt"><button className='search_link'>検索</button></Link>

            {contents.map((result) => (
                <div key={result} >
                    <Content_mini result={result}></Content_mini>

                </div>
            ))}
            <News />
            <TopComment />
        </div>
    )
}

export default TopPage