import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
    return (
        <div>
            <p>Error:記事が見つかりません</p>
            <Link to="/">戻る</Link>
        </div>
    )
}

export default Error