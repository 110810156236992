import React, { useEffect, useState } from 'react'

import firebase from 'firebase/compat/app';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Content_mini from './Content_mini';
import "./TopPage.css"
import Search from './Search';
import Error from './Error';


function Serch_result() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mapParam = queryParams.get("map");
    const characterParam = queryParams.get("character");
    const tagParam = queryParams.get("tag");
    const orderBy = queryParams.get("orderBy");

    let isMatch = true
    let isShow = false

    const [results, setResults] = useState([]);

    useEffect(() => {
        { document.title = "検索" }
        let query = firebase.firestore().collection("contents");

        if (mapParam) {
            query = query.where("map", "==", mapParam);
        }
        if (characterParam) {
            query = query.where("character", "==", characterParam);
        }
        { console.log(query) }

        query.orderBy(orderBy, "desc").get().then((querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setResults(data);
        });
    }, [mapParam, characterParam, orderBy]);
    function Show(serch_str) {
        if (serch_str) {
            serch_str = serch_str.split(",")

            return (
                results.map((result) => (
                    <>
                        {isMatch = true}
                        {serch_str.forEach(serch_str => {
                            if (!result.tags.includes(serch_str)) {
                                isMatch = false;
                            }
                        })
                        }
                        {isMatch ? <div key={result.id} className="content_list" >{isShow = true}<Content_mini result={result}></Content_mini>
                        </div> : <></>}
                    </>
                ))
            )

        } else {

            if (results.length != 0) {
                return (
                    results.map((result) => (
                        <div key={result.id} className="content_list">

                            <Content_mini result={result}></Content_mini>
                            {isShow = true}

                        </div>

                    ))
                )
            }
        }
    }


    return (

        <div>
            <div className='result_list' style={{ backgroundColor: "black", padding: "6px" }}>
                <Search></Search>
                {Show(tagParam)}
                {isShow ? <></> : <Error></Error>}
            </div >
        </div>
    )
}

export default Serch_result