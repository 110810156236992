import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import "./Search.css"
const Search = () => {
    const history = useNavigate();



    const [selectedMap, setSelectedMap] = useState("map");
    const [selectedCharacter, setSelectedCharacter] = useState("agent");
    const [selectedTags, setSelectedTags] = useState([]);
    const [orderBy, setOrderBy] = useState("createdAt");
    const mapData = ["map", "ascent", "bind", "breeze", "fracture", "haven", "icebox", "lotus", "pearl", "split"]
    const mapDataJP = ["マップを選択", "アセント", "バインド", "ブリーズ", "フラクチャー", "ヘイヴン", "アイスボックス", "ロータス", "パール", "スプリット"]
    const characterData = ["agent", "jett", "raze", "breach", "omen", "brimstone", "phoenix", "sage", "sova", "viper", "cypher", "reyna", "killjoy", "sky", "yoru", "astra", "kayo", "chamber", "neon", "fade", "harbor"]
    const characterDataJP = ["エージェントを選択", "ジェット", "レイズ", "ブリーチ", "オーメン", "ブリムストーン", "フェニックス", "セージ", "ソーヴァ", "ヴァイパー", "サイファー", "レイナ", "キルジョイ", "スカイ", "ヨル", "アストラ", "KAY/O", "チェンバー", "ネオン", "フェイド", "ハーバー"]

    const [tagsData, setTagsData] = useState([])
    useEffect(() => {
        db
            .collection("tags")
            .orderBy("createdAt", "asc")
            .onSnapshot((snapshot) => {
                setTagsData(snapshot.docs.map((doc) => doc.data()));
            })

    }, [])

    const imgURL1 = "https://firebasestorage.googleapis.com/v0/b/teten-6e02b.appspot.com/o/image%2F"
    const imgURL2 = "_icon.png?alt=media&token=4ef696f4-0506-4508-9e15-ce856a62876e"
    const handleSearch = () => {
        let path = "/Search_result?";
        if (selectedMap) {
            if (selectedMap != "map") {
                path += "map=" + selectedMap + "&";
            }
        }
        if (selectedCharacter && selectedCharacter != "agent") {
            path += "character=" + selectedCharacter + "&";
        }
        if (selectedTags.length > 0) {
            path += "tag=" + selectedTags.join(",") + "&";
        }
        path += "orderBy=" + orderBy + "&"
        history(path.slice(0, -1));
    };

    const handleReset = () => {
        setSelectedMap("map");
        setSelectedCharacter("agent");
        setSelectedTags([]);
    };

    const handleCheckboxChange = (e) => {
        const selectedFruit = e.target.value;
        if (selectedTags.includes(selectedFruit)) {
            setSelectedTags(selectedTags.filter((fruit) => fruit !== selectedFruit));
        } else {
            setSelectedTags([...selectedTags, selectedFruit]);
        }
    };
    function orderByChange(e) {
        setOrderBy(e.target.value)
    }
    function pullDown(options, jp) {
        {
            return (options.map((data, index) => (
                <option key={data} value={data}>{jp[index]}</option>
            )))
        }

    }
    return (
        <div className="search">
            <div className="search_item">
                <p className="search_item_name">マップ</p>
                <img src={imgURL1 + selectedMap + imgURL2} className="icon map"></img><br></br>

                <select value={selectedMap} onChange={(e) => setSelectedMap(e.target.value)} className="search_item_pulldown">

                    {pullDown(mapData, mapDataJP)}
                </select>

            </div>
            <div className="search_item">
                <p className="search_item_name">エージェント</p>
                <img src={imgURL1 + selectedCharacter + imgURL2} className="icon map"></img><br></br>
                <select value={selectedCharacter} onChange={(e) => setSelectedCharacter(e.target.value)} className="search_item_pulldown">

                    {pullDown(characterData, characterDataJP)}
                </select>
            </div>
            <div>
                <div className="tags"><p style={{ margin: 0 }}>タグ</p>
                    {tagsData.map((tag) => {
                        if (tag.tag == "DEF") {
                            return (

                                <label key={tag.tag}>

                                    <input
                                        type="checkbox"
                                        value={tag.tag}
                                        checked={selectedTags.includes(tag.tag)}
                                        onChange={handleCheckboxChange}
                                    />{tag.tag}
                                    <br></br>
                                </label>
                            )
                        } else {
                            return (

                                <label key={tag.tag}>

                                    <input
                                        type="checkbox"
                                        value={tag.tag}
                                        checked={selectedTags.includes(tag.tag)}
                                        onChange={handleCheckboxChange}
                                    />{tag.tag}
                                </label>
                            )
                        }
                    })}
                </div>
                <div className="sort"> <p style={{ margin: 0 }}>並び順
                    <input type="radio" name='aa' value="star_Ave" checked={orderBy === "star_Ave"} onChange={orderByChange} />
                    <label>評価</label>
                    <input type="radio" name='aa' value="createdAt" checked={orderBy === "createdAt"} onChange={orderByChange} />
                    <label>日付</label></p>
                </div>

            </div>
            <div className="button">
                <button onClick={handleReset}>リセット</button>
                <button onClick={handleSearch} className="search_button">検索</button>
            </div>

        </div >
    );
};

export default Search;
