import React, { useState } from 'react'
import { db } from '../firebase';
import firebase from 'firebase/compat/app';
import { Link, useNavigate } from 'react-router-dom';
import "./TopComment.css"

function TopComment() {
    const [topSendMessage, setTopSendMessage] = useState([])
    const navigate = useNavigate();

    function topSendMessagefun(e) {

        if (topSendMessage) {


            e.preventDefault();

            db.collection("comments").add({
                text: topSendMessage,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                contentId: "TOP"
            });
            setTopSendMessage("")
            alert("送信しました。内容: " + topSendMessage)
        }
    }
    return (
        <div style={{ padding: "6px" }}>
            <p >ご意見、ご感想、不具合や、おすすめの定点、セットアップなどございましたらお気軽に <Link to={"/form"} state={{
                name: "", email: "", message: "", reply: false
            }} id='kotira'> こちら </Link>から。</p>


        </div >
    )
}

export default TopComment