import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Form.css"
function Form() {
    const location = useLocation();
    const [name, setName] = useState(location.state.name);
    const [email, setEmail] = useState(location.state.email);
    const [message, setMessage] = useState(location.state.message);
    const [reply, setReply] = useState(location.state.reply);
    const navigate = useNavigate();



    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/Form_result", { state: { name: name, email: email, message: message, reply: reply } })
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            {document.title = "問合せ"}
            <label htmlFor="name">お名前{reply ? "【必須項目】" : ""}</label>
            <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                {...reply && { required: true }}
            />
            <label htmlFor="email">メールアドレス{reply ? "【必須項目】" : ""}</label>
            <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                {...reply && { required: true }}
            />
            <label htmlFor="message">お問い合わせ内容【必須項目】</label>
            <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
            ></textarea>
            <label htmlFor="reply">返信を希望しますか？</label>
            <div className="radio-group">
                <label>
                    <input
                        type="radio"
                        name="reply"
                        value="yes"
                        checked={reply == true}
                        onChange={() => setReply(true)}
                    />
                    はい
                </label>
                <label>
                    <input
                        type="radio"
                        name="reply"
                        value="no"
                        checked={reply == false}
                        onChange={() => setReply(false)}
                    />
                    いいえ
                </label>
            </div>

            <button type="submit">送信確認</button>
        </form>
    );
}
export default Form