import React, { useEffect, useState } from 'react'
import { db } from '../firebase';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';

function News() {
    const [news, setNews] = useState([]);
    useEffect(() => {
        db
            .collection("news")
            .orderBy("createdAt", "desc")
            .limit(10)
            .onSnapshot((snapshot) => {
                setNews(snapshot.docs.map((doc) => doc.data()));
            })

    }, [])
    return (
        <div>
            <div style={{ padding: " 6px", margin: "6px" }}>
                <p><strong>お知らせ</strong></p>

                {news.map((result) => {
                    return (<><Link to={"/news_page"} state={{ arrayData: result }} > <p style={{ margin: "0 15px", textDecoration: "underline" }}>{result.newsTitle}</p></Link><br></br></>)
                })}
            </div>
        </div>
    )
}

export default News