import Content from "./Conponents/Content";
import Header from "./Conponents/Header";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopPage from "./Conponents/TopPage";
import Search_result from "./Conponents/Search_result";
import "./App.css"
import Footer from "./Conponents/Footer";
import Form from "./Conponents/Form";
import Form_result from "./Conponents/Form_result";
import News_page from "./Conponents/News_page";


function App() {

  return (
    <div className="App">

      <Header>

      </Header>
      <main>
        <Router>
          <Routes>
            <Route path="/" element={<TopPage />} />
            <Route path="/Search_result" element={<Search_result />} />
            <Route path="/form" element={<Form />} />
            <Route path="/form_result" element={<Form_result />} />
            <Route path="/Content/:id" element={<Content />} />
            <Route path="/News_page" element={<News_page />} />

          </Routes>
        </Router>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
