import React, { useEffect, useState } from 'react'
import Comments_list from "./Comments_list"
import Star from "./Star"
import "./Content.css"
import arrow from "../Img/arrow.png"

import { Link, useNavigate, useParams } from 'react-router-dom'
import { db } from '../firebase'

import Error from './Error'
function Content() {
    const { id } = useParams();
    const [content, setContent] = useState([]);
    const [changeflg, setChangeFlg] = useState(true)
    useEffect(() => {
        db.collection("contents")
            .where("contentId", "==", id)
            // .limit(10)
            .onSnapshot((snapshot) => {
                setContent(snapshot.docs.map((doc) => doc.data()));
                document.title = "【VALORANT/定点】" + snapshot.docs.map((doc) => doc.data().contentTitle + "【" + doc.data().mapName + "/" + doc.data().characterName + "】")


            })
    }, [])

    const imgSet = (imageName) => {

        const aaa = "https://firebasestorage.googleapis.com/v0/b/valoteiten-e3409.appspot.com/o/image%2F" + imageName + "?alt=media&token=8e69bd82-65bc-4012-8d2b-7d8d1bccdee7"
        return (imageName ? <img src={aaa} className="content_img"></img> : <></>)
    };
    function changeImg() {
        setChangeFlg(!changeflg)
    }
    const getYouTubeId = (url) => {
        if (url) {
            const matches = url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/);
            return matches[1];
        } else {
            return "jNQXAC9IVRw";
        }

    };
    const showTags = (tags) => {
        if (tags) {
            const tags_list = tags.split(",")
            return (
                tags_list.map((tag) => {
                    return (<Link key={tag} to={"/Search_result?tag=" + tag + "&orderBy=createdAt"} style={{ margin: "0 6px", color: "rgb(29, 155, 240)" }}>#{tag}</Link>)
                }))
        }

    }
    return (
        <div >

            {content.length != 0 ? <>{content.map((result) => (
                <div key={result}>

                    <div className='main_area'>

                        <div className='content'>
                            <div className='title'>
                                <h2 className='content_number'>No.{result.contentNo}</h2>
                                <h2 className='content_title'>{result.contentTitle}</h2>
                                <div className='title_migihasi'>
                                    <p className='content_addtime'>投稿:{result.createdAt.toDate().toLocaleDateString()}</p>
                                    <p className='latestpatch'>最終確認パッチ:{result.latestPatch}</p>
                                </div>

                            </div>
                            <p className='tag_area'>タグ： {showTags(result.tags)}
                            </p>

                            <section className='img_area'>
                                <div className='left_area'>
                                    <div className='icon_area'>
                                        <div className='icon characterImg'>
                                            {imgSet("icon%2Fagent%2F" + result.characterImg)}
                                        </div>
                                        <div className='icon skillImg'>

                                            {imgSet("icon%2Fskill%2F" + result.skillImg)}
                                        </div>
                                        <div className='icon mapImg'>


                                            {imgSet("icon%2Fmap%2F" + result.mapImg)}
                                        </div>
                                    </div>
                                    <div className='setumei'>
                                        {changeflg ? result.set.replaceAll('\\n', "\n") : result.result.replaceAll('\\n', "\n")}
                                    </div>
                                </div>
                                <div className='resultImg'>


                                    {imgSet(changeflg ? result.setImg : result.resultImg)}

                                    <button className='next_button' onClick={changeImg}><img src={arrow}></img></button>
                                </div>


                            </section>
                            <section className='movie_area'>
                                <div className='movie'>

                                    <iframe
                                        src={`https://www.youtube.com/embed/${getYouTubeId(result.movie)}`}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>

                            </section>
                            <section className='item syousai'>
                                {result.value ? <p>補足:{result.value}</p> : <></>}
                                {result.link ? <p >参考リンク:<a style={{ wordBreak: "break-all" }} href={result.link} target="_blank">{result.link}</a></p> : <></>}
                                <a id='twitter' href={"https://twitter.com/share?url=https://valo-teiten.com/content/" + result.contentId + "&hashtags=VALORANT,定点&text=" + result.contentTitle + "@PlayVALORANT @ValoteitenSite"} rel="nofollow"
                                    target="_blank"
                                >Twitterで共有
                                </a>

                            </section>


                        </div>
                        <aside className='comment_area'>
                            <Star id={result.contentId}></Star>
                            <Comments_list id={result.contentId}></Comments_list>
                        </aside>
                    </div>
                </div>
            ))
            }
            </> : <Error></Error>
            }


        </div >
    )
}

export default Content