import React from 'react'
import { Link } from 'react-router-dom';
import "./Content_mini.css"

function Content_mini(props) {
    const result = props.result
    const imgSet = (imageName) => {
        var aaa = ""
        if (imageName) { aaa = "https://firebasestorage.googleapis.com/v0/b/valoteiten-e3409.appspot.com/o/image%2F" + imageName + "?alt=media&token=8e69bd82-65bc-4012-8d2b-7d8d1bccdee7" }

        return (<img src={aaa} alt={"resultimage"} style={{ width: '35%', minWidth: "250px", marginRight: '20px' }} />)
    };
    return (
        <div className="link">
            <Link to={"/content/" + result.contentId} >
                <div style={{ display: 'flex', alignItems: 'center', margin: '6px', padding: "6px" }}>
                    {imgSet(result.resultImg)}
                    <div>
                        <h3 style={{ fontSize: '18px', marginBottom: '10px' }} className="content_mini_title">{result.contentTitle}</h3>
                        <p style={{ fontSize: '14px', marginBottom: '10px' }}>{"作成日 " + result.createdAt.toDate().toLocaleDateString()}{"   平均評価 "} {result.star_sum / result.num_sum ? Math.round((result.star_sum / result.um_sum) * 10) / 10 : "未評価"}</p>

                        <p style={{ fontSize: '14px', display: "inline", marginRight: "12px" }}>{"エージェント:" + result.characterName}</p>
                        <p style={{ fontSize: '14px', display: "inline", margin: "12px" }}>{"マップ:" + result.mapName}</p>
                        <p style={{ fontSize: '14px', display: "inline", margin: "12px" }}>{"スキル:" + result.skillName}</p>

                        <p style={{ fontSize: '14px', }}>{"タグ:" + result.tags}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Content_mini 