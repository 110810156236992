import React, { useEffect, useState } from 'react';
import star_img from "../Img/star.jpg"
import stard_img from "../Img/stard.jpg"
import "./star.css"

import { db } from '../firebase'
import firebase from 'firebase/compat/app';


const StarRating = (props) => {

    const [star, setStar] = useState([])
    const [buttonShow, setButtonShow] = useState(false)
    const [buttonStr, setButtonStr] = useState("送信")
    const star_hyouka = []
    var star_sum
    var num_sum


    star.map(({ star, star_Sum }) => (
        star_sum = star,
        num_sum = star_Sum
    ))

    useEffect(() => {
        db
            .collection("contents")
            .where("contentId", "==", props.id)
            .onSnapshot((snapshot) => {
                setStar(snapshot.docs.map((doc) => doc.data()));
            })

        //今の情報を入手

    }, []);

    const [ratingState, setRatingState] = useState(0);
    const stars = [];
    for (let i = 0; i < 5; i++) {
        if (i < ratingState) {
            stars.push(true);
        } else {
            stars.push(false);
        }
    }
    const handleStarShine = (rating) => {
        setRatingState(rating + 1); // index番号は04だから星の数に合わせて15にする
    };

    function submitStar() {
        let star_add = 0
        for (let i = 1; i < 6; i++) {
            if (!stars[i]) {
                star_add += i;
                i = 5;
            }
        }
        // Firestore の初期化
        const db = firebase.firestore();

        //今の情報を入手
        var star_sum
        var num_sum
        star.map(({ star, star_Sum }) => (
            star_sum = star,
            num_sum = star_Sum
        ))
        // データの上書き
        db.collection("contents")
            .where("contentId", "==", props.id)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                        star: star_sum + star_add,
                        star_Sum: num_sum + 1,
                        star_Ave: (star_sum + star_add) / (num_sum + 1),
                    });
                });
            });
        //ボタンの見た目変更
        setButtonShow(true)
        setButtonStr("送信済み")
    }

    for (let i = 1; i < 6; i++) {
        star_hyouka.push(<>{i <= Math.round(star_sum / num_sum) ? <img className='star_score' src={star_img} /> : <img className='star_score' src={stard_img} />}</>);
    }
    return (
        <div>
            {star.map(({ id }) => (
                <div key={id}>
                    <p className='star_ave'>{star_sum / num_sum ? Math.round((star_sum / num_sum) * 10) / 10 : "未評価"}</p>
                </div>
            ))}
            <div className='star_score_list'>
                <p> {star_hyouka}{"(" + num_sum + ")"}</p>
            </div>
            <br></br>
            <div className='star_submit_list'>
                <div className='star_list'>
                    {stars.map((val, index) => {
                        return (
                            <>
                                <button className='star' onClick={() => handleStarShine(index)} >
                                    {val ? <img className='star' src={star_img} /> : <img className='star' src={stard_img} />}

                                </button>

                            </>
                        );
                    })}
                </div>
                <button type='submit' onClick={() => submitStar()} disabled={buttonShow} style={{ height: "100%" }} className="star_submit">{buttonStr}</button>
            </div>



        </div>
    );
};

export default StarRating;